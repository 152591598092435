import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import type { JSX } from 'vue/jsx-runtime'
import { routes } from 'vue-router/auto-routes'
import { useCommonStore } from '~/stores/common'

export enum RouteType {
    InnerLink = 'InnerLink',
}

export enum MenuType {
    Directory = 'D',
    Menu = 'M',
    Button = 'B',
}
export interface NavDataOrigin {
    key?: number | string | symbol
    children?: NavDataOrigin[]
    label?: string | JSX.Element
    path?: string
    parent?: { label?: string | JSX.Element, children?: NavDataOrigin[] } | NavDataOrigin
    name?: string
    hidden?: boolean
    menuType?: MenuType
    redirect?: string
    query?: string
    alwaysShow?: boolean
    component?: string | RouteType
    meta?: RouteMeta
    rootKey?: string
    isFrame?: '1' | '0'
}

export interface RouteMeta {
    title: string
    icon: string
    noCache: boolean
    link: string
}

export const layoutRoutes = setupLayouts(routes)

console.log(layoutRoutes)

export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: layoutRoutes,
})

export const routeMap = new Map<string, NavDataOrigin>()

export function normalizePath(path: string) {
    if (!path) return ''
    if (path[0] !== '/')
        return `/${path}`
    return path
}

export function deNormalizePath(path: string) {
    const arr = path.split('/')
    if (path[0] === '/')
        arr.shift()
    return arr.join('/')
}

export function formatRoutes<T = Record<string, unknown>>(source: T[], parentName?: string, rootKey?: string) {
    for (let i = 0; i < source.length; i++) {
        const item = (source as NavDataOrigin[])[i]
        // if (item.hidden) console.log(item);
        if (rootKey)
            item.rootKey = rootKey
        if (item.path && item.component !== RouteType.InnerLink) {
            item.path = normalizePath(item.path).trim()
            routeMap.set(item.path, item)
        }
        item.key = item.name ?? (item.path as string)
        if (item.children)
            item.children = formatRoutes(item.children, item.path, rootKey ?? item.key)
    }
    return source.filter(i => !(i as NavDataOrigin).hidden)
}

router.beforeEach((to, from, next) => {
    const commonStore = useCommonStore()
    const url = new URL(window.location.href)
    if (url.searchParams.has('showMenu'))
        commonStore.showMenu = url.searchParams.get('showMenu') === '1'

    next()
})

export const menuList = [
    {
        key: 'system',
        children: [
            {
                key: 'products',
                label: '产品管理',
                path: '/home/system/products',
                name: '产品管理',
                menuType: MenuType.Menu,
                rootKey: 'system',
                meta: {
                    title: '产品管理',

                },
            },
            {
                key: 'swipes',
                label: '轮播图管理',
                path: '/home/system/swipes',
                name: '轮播图管理',
                menuType: MenuType.Menu,
                rootKey: 'system',
                meta: {
                    title: '轮播图管理',

                },
            },
            {
                key: 'news',
                label: '新闻管理',
                path: '/home/system/news',
                name: '新闻管理',
                menuType: MenuType.Menu,
                rootKey: 'system',
                meta: {
                    title: '新闻管理',

                },
            },
            {
                key: 'accounts',
                label: '账号管理',
                path: '/home/system/accounts',
                name: '账号管理',
                menuType: MenuType.Menu,
                rootKey: 'system',
                meta: {
                    title: '账号管理',

                },
            },
            {
                key: 'footer-links',
                label: '底部链接管理',
                path: '/home/system/footer-links',
                name: '底部链接管理',
                menuType: MenuType.Menu,
                rootKey: 'system',
                meta: {
                    title: '底部链接管理',
                },
            },
            {
                key: 'back-to-top',
                label: '回到顶部图片',
                path: '/home/system/back-to-top',
                name: '回到顶部图片',
                menuType: MenuType.Menu,
                rootKey: 'system',
                meta: {
                    title: '回到顶部图片',

                },
            },
        ],
        label: '系统',
        path: '/home/system',
        name: '系统',
        menuType: MenuType.Directory,
        rootKey: 'system',
    },
] as NavDataOrigin[]
