import type { MessageContext } from 'vue-i18n'

export default {
    'auth': {
        login: 'Login',
        username: 'Username',
        username_placeholder: 'Enter username',
        password: 'Password',
        password_placeholder: 'Enter password',
        code: 'Verify Code',
        code_placeholder: 'Enter verify code',
        logout: 'Logout',
        logout_success: 'Logout success',
    },
    'page': {
        document: {
            document: 'document',
        },
        global: {
            page_search: 'page search',
        },
        error: {
            not_found: 'Not found',
            not_found_des: 'There is nothing here',
            auth_failed: 'Auth Failed',
            auth_failed_des: 'This page is not reachable',
        },
    },
    'request': {
        auth: {
            login_success: 'Login Success',
        },
    },
    'button': {
        new: 'new',
        search: 'search',
        reset: 'reset',
        confirm: 'confirm',
        cancel: 'cancel',
        edit: 'edit',
        delete: 'delete',
        show_theme_editor: 'show theme editor',
        back: 'back',
    },
    'date': {
        createAt: 'createAt',
    },
    'title': 'title',
    'jpTitle': 'jpTitle',
    'content': 'content',
    'jpContent': 'jpContent',
    'intro': '简介',
    'jpIntro': '日文简介',
    'top': 'pin',
    'image': 'image',
    'jpImage': 'jpImage',
    'mdEditor': 'Edit Content',
    'mdJpEditor': 'Edit Japanese Content',
    'action': 'action',
    'news': 'news',
    'confirm': {
        confirm_deletion: 'Do you reeally want to delete?',
    },
    'name': 'name',
    'jpName': 'jpName',
    'url': 'url',
    'jpUrl': 'jpUrl',
    'type': 'type',
    'sort': 'sort',
    'category': 'category',
    'tag': 'tag',
    'jpTag': 'jpTag',
    'product': 'product',
    'swipe': 'swipe',
    'account': 'account',
    'back-to-top': 'back-to-top',
    'saveFirst': 'Please Save at first',
    'dropdown': {
        close_others: 'close others',
        close_right: 'close right',
        close_left: 'close left',
    },
    'validation': {
        common: {
            required: 'Item is required',
            numeric: 'Must be integer',
            decimal: 'Must be number',
            positive_interger: 'Must be positive interger',
            up_to_digits: ({ named }: MessageContext) => `Up to ${named('number')} digits`,
            more_than_characters: ({ named }: MessageContext) =>
                `More than ${named('number')} characters`,
            max_bytes: ({ named }: MessageContext) => `Exceed max bytes (${named('number')})`,
            less_than_characters: ({ named }: MessageContext) =>
                `Less than ${named('number')} characters`,
            must_be_characters: ({ named }: MessageContext) =>
                `Must be ${named('number')} characters`,
            greater_than: ({ named }: MessageContext) => `Greater than ${named('number')}`,
            less_than: ({ named }: MessageContext) => `Less than ${named('number')}`,
            up_to_2_decimal_places: 'Up to 2 decimal places',
            retain_up: 'Enter up to 10 positive integers and retain up to 4 decimal places',
            retain_ups: 'Enter up to 11 positive integers, and retain up to 4 decimal places',
            input_number: 'Please enter the number',
            only_number_a: 'Only letters or numbers can be entered',
            positive_integer: 'Enter at least one field',
            at_least_one: 'Fill in at least one',
        },
        tip: {
            incomplete_information: 'Incomplete Information',
            MG_information: 'MG orderer cannot be empty',
            MAXUS_information: 'MAXUS orderer cannot be empty',
        },
        cipher: {
            cipher_required: 'Cipher is required',
            cipher_rule_message:
                'The password must contain uppercase letters, lowercase letters, numbers, and special characters ({\'@\'}$!_~&*), and the length of the password should be between 8 to 16 characters.',
        },
    },
}
