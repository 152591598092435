import type { DropdownOption } from 'naive-ui'
import { Locales, useTypedI18n } from './i18n'
import { useCommonStore } from '~/stores/common'

const availableLocales = ref<{ langCode: string, langName: string }[]>([
    {
        langCode: 'en-US',
        langName: 'en-US',
    },
    {
        langCode: 'zh-CN',
        langName: 'zh-CN',
    },
])

export function useAvailableLocaleList() {
    return {
        availableLocales,
    }
}

export function useGlobalLanguage() {
    const commonStore = useCommonStore()
    const { t, locale } = useTypedI18n()
    const { availableLocales } = useAvailableLocaleList()

    const options = computed<DropdownOption[]>(() => {
        return [
            { key: Locales.enUS, label: 'en-US' },
            { key: Locales.zhCN, label: 'zh-CN' },
        ]
    },
    // availableLocales.value.map((i) => (i)),
    )

    const nameMapRef = computed(() => {
        const map = new Map<string, string>()
        availableLocales.value.forEach((i) => {
            map.set(i.langCode, i.langName)
        })
        return map
    })

    const handleSelect = async (key: Locales, option: DropdownOption) => {
        console.log(key)

        locale.value = key
        commonStore.locale = key
    }

    return {
        t,
        options,
        handleSelect,
        locale,
        nameMapRef,
    }
}
