import { createPinia } from 'pinia'
import { createDefaultSettings } from 'navuelidate'
import { setGlobalOptions } from 'v-demi-request'
import { createHead } from '@unhead/vue'
import App from './App.vue'
import { router } from './utils/router'
import { initToken } from '~/utils/token'
import { i18n, initI18nScope } from '~/utils/i18n'

import '@unocss/reset/normalize.css'
import './styles/main.css'
import 'uno.css'
import './utils/dayjs'
import { initAxios } from '~/utils/http/axios'

if (import.meta.hot) {
    import.meta.hot.accept()
}

(window as any).global = window

initAxios()
createDefaultSettings({
    formItemGiProps: {
        labelAlign: 'left',
    },
})
setGlobalOptions({
    retry: false,
})
const head = createHead()
const pinia = createPinia()
const app = createApp(App)
app.use(router).use(pinia).use(i18n).use(head).mount('#app')
initToken()
initI18nScope()
