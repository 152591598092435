import type { MessageContext } from 'vue-i18n'

export default {
    'auth': {
        login: '登录',
        username: '用户名',
        username_placeholder: '请输入用户名',
        password: '密码',
        password_placeholder: '请输入密码',
        code: '验证码',
        code_placeholder: '请输入验证码',
        logout: '注销',
        logout_success: '注销成功',
    },
    'page': {
        document: {
            document: '文档',
        },
        global: {
            page_search: '页面搜索',
        },
        error: {
            not_found: '未找到页面',
            not_found_des: '这里没有任何东西',
            auth_failed: '权限不足',
            auth_failed_des: '此页面禁止访问',
        },
    },
    'request': {
        auth: {
            login_success: '登录成功',
        },
    },
    'button': {
        new: '新增',
        search: '查询',
        reset: '重置',
        confirm: '确认',
        cancel: '取消',
        edit: '修改',
        delete: '删除',
        show_theme_editor: '显示主题编辑器',
        back: '返回',
    },
    'date': {
        createAt: '创建时间',
    },
    'title': '标题',
    'jpTitle': '日文标题',
    'content': '内容',
    'jpContent': '日文内容',
    'intro': '简介',
    'jpIntro': '日文简介',
    'image': '图片',
    'jpImage': '日文图片',
    'mdEdit': '编辑内容',
    'mdJpEdit': '编辑日文内容',
    'top': '置顶',
    'action': '操作',
    'news': '新闻',
    'confirm': {
        confirm_deletion: '确认删除吗',
    },
    'name': '名称',
    'jpName': '日文名称',
    'url': '链接',
    'jpUrl': '日文链接',
    'type': '类型',
    'sort': '排序',
    'category': '分类',
    'tag': '标签',
    'jpTag': '日文标签',
    'product': '产品',
    'swipe': '轮播图',
    'account': '账号',
    'back-to-top': '回到顶部',
    'saveFirst': '请先保存',
    'dropdown': {
        close_others: '关闭其他',
        close_right: '关闭右侧',
        close_left: '关闭左侧',
    },
    'validation': {
        common: {
            required: '此项必填',
            numeric: '必须为整数数字',
            decimal: '必须为数字',
            positive_interger: '必须为正整数',
            up_to_digits: ({ named }: MessageContext) => `最多为${named('number')}位数`,
            more_than_characters: ({ named }: MessageContext) => `不能超过${named('number')}字`,
            max_bytes: ({ named }: MessageContext) => `超过最大字符数(${named('number')})`,
            less_than_characters: ({ named }: MessageContext) => `不少于${named('number')}字`,
            must_be_characters: ({ named }: MessageContext) => `必须为${named('number')}字`,
            greater_than: ({ named }: MessageContext) => `不能大于${named('number')}`,
            less_than: ({ named }: MessageContext) => `不能小于${named('number')}`,
            up_to_2_decimal_places: '最多为2位小数',
            retain_up: '最多输入10位正整数，最多保留2位小数',
            retain_ups: '最多输入11位正整数，最多保留4位小数',
            input_number: '请输入数字',
            only_number_a: '只能输入字母或数字',
            translate: '只能为字母',
            positive_integer: '至少输入一个字段',
            at_least_one: '至少填写一个',
        },
        tip: {
            incomplete_information: '信息未填写完整',
            MG_information: 'MG订单员不能为空',
            MAXUS_information: 'MAXUS订单员不能为空',
        },
        cipher: {
            cipher_required: '密码不能为空',
            cipher_rule_message:
                '密码必须包含大写字母,小写字母、数字、特殊符号（{\'@\'}$!_~&*），密码长度在 8-16 位',
        },
    },
}
