export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/index.vue'),
    /* no children */
    meta: {
      "layout": "home"
    }
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": "default"
    }
  },
  {
    path: '/403',
    name: '/403',
    component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/403.vue'),
    /* no children */
    meta: {
      "layout": "default"
    }
  },
  {
    path: '/auth',
    /* internal name: '/auth' */
    /* no component */
    children: [
      {
        path: '',
        name: '/auth/',
        component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/auth/index.vue'),
        /* no children */
        meta: {
          "layout": "home"
        }
      },
      {
        path: 'login',
        /* internal name: '/auth/login' */
        /* no component */
        children: [
          {
            path: '',
            name: '/auth/login/',
            component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/auth/login/index.vue'),
            /* no children */
            meta: {
              "layout": "authl"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/home',
    /* internal name: '/home' */
    /* no component */
    children: [
      {
        path: 'editor',
        /* internal name: '/home/editor' */
        /* no component */
        children: [
          {
            path: ':id',
            name: '/home/editor/[id]',
            component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/home/editor/[id].vue'),
            /* no children */
            meta: {
              "layout": "empty"
            }
          }
        ],
      },
      {
        path: 'empty',
        /* internal name: '/home/empty' */
        /* no component */
        children: [
          {
            path: '',
            name: '/home/empty/',
            component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/home/empty/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'main',
        /* internal name: '/home/main' */
        /* no component */
        children: [
          {
            path: '',
            name: '/home/main/',
            component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/home/main/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'system',
        /* internal name: '/home/system' */
        /* no component */
        children: [
          {
            path: 'accounts',
            /* internal name: '/home/system/accounts' */
            /* no component */
            children: [
              {
                path: '',
                name: '/home/system/accounts/',
                component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/home/system/accounts/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'back-to-top',
            /* internal name: '/home/system/back-to-top' */
            /* no component */
            children: [
              {
                path: '',
                name: '/home/system/back-to-top/',
                component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/home/system/back-to-top/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'footer-links',
            /* internal name: '/home/system/footer-links' */
            /* no component */
            children: [
              {
                path: '',
                name: '/home/system/footer-links/',
                component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/home/system/footer-links/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'news',
            /* internal name: '/home/system/news' */
            /* no component */
            children: [
              {
                path: '',
                name: '/home/system/news/',
                component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/home/system/news/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'products',
            /* internal name: '/home/system/products' */
            /* no component */
            children: [
              {
                path: '',
                name: '/home/system/products/',
                component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/home/system/products/index.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'swipes',
            /* internal name: '/home/system/swipes' */
            /* no component */
            children: [
              {
                path: '',
                name: '/home/system/swipes/',
                component: () => import('/data/project/TAMAMAKO-OFW-BACKEND-ADMIN/src/pages/home/system/swipes/index.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

